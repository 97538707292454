import ui from "@snark/backoffice/src/helpers/ui";
import axios from "axios";
import moment from "moment-timezone";

export async function exportParticipations() {
	try {
		const data = await axios.get(process.env.VUE_APP_BASEAPI_URL + "/participations/export");
		console.log("exported: ", data.data);
		let blob = b64toBlob(data.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		saveAs(blob, "export-participations-" + moment().format("YYYY-MM-DD") + ".xlsx");
	}
	catch(err) {
		console.log("Error exporting participations: ", err);
		ui.notifyError("Impossible d'exporter. Veuillez réessayer dans quelques instants.");
	}
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, {type: contentType});
}