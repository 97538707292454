import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
};

export default {
	name: "user",
	collection: "user",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"lastname"},{"field":"firstname"}]},
	pagination: 50,
	backTextFilterList: true,
	viewActions: [
		{
			type: "logAs",
			logAs: {"field":"email"},
		},
	],
	labels: {"singleTitle":"User","listTitle":"Users"},
	emptyItem: {"picture":{}},
	fields: [
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "email",
			label: "Email",
		},
		{
			name: "password",
			label: "Password",
			writeOnly: true,
		},
		{
			name: "phone",
			label: "Phone",
		},
		{
			name: "gender",
			label: "Gender",
			formatter: Backoffice.formatters.mapping({"female": "Female", "male": "Male", "none": "None"}),
			editor: {type: "select", options: [{value: "female", label: "Female"}, {value: "male", label: "Male"}, {value: "none", label: "None"}]},
		},
		{
			name: "picture",
			label: "Picture",
			children: [
				{
					name: "path",
					label: "Path",
				},
				{
					name: "thumbnail",
					label: "Thumbnail",
				},
				{
					name: "mimetype",
					label: "Mimetype",
				},
			],
		},
		{
			name: "termsAccepted",
			label: "Terms Accepted",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "imageRight",
			label: "Image Right",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "partnerAccepted",
			label: "Partner Accepted",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "token",
			label: "Token",
			readOnly: true,
		},
		{
			name: "tokenExpire",
			label: "Token Expire",
			readOnly: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "resetToken",
			label: "Reset Token",
			readOnly: true,
		},
		{
			name: "resetTokenExpire",
			label: "Reset Token Expire",
			readOnly: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "email",
			label: "Email",
		},
		{
			name: "gender",
			label: "Gender",
			formatter: Backoffice.formatters.mapping({"female": "Female", "male": "Male", "none": "None"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	loadSingle() {
		return Promise.all([
		]);
	},
};